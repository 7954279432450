<template>
  <div>Welcome to 北坡课堂 {{nickname}}</div>
</template>

<script>
export default {
  data() {
    return {
      nickname: ''
    }
  },
  mounted() {
    this.nickname = this.$store.getters.getUserInfo.nickname
  },
}
</script>

<style>

</style>
